'use strict';

angular.module('myApp.PlannerService', [])

.service('PlannerService', function (UserService, $filter) {

    var self = this;
//    var GROWTHRATE = 0.04;
    var GROWTHRATE = 0.015;
//    var BOOSTGROWTHRATE = 0.0451;
    var BOOSTGROWTHRATE = 0.0201;
//    var INCOMERATE = 0.07;
    var INCOMERATE = 0.06;

    this.plannerBoosted = function (callback) {
        var data = {
            action: "plannerBoosted",
            user : UserService.getUser(),
            session : UserService.getSession()
        };

        PersistanceService.sessionAction(data).then(function(response){

            callback();
        });

    }

    this.totalPensionContributions = function(pensions, pensionCount) {
        var data = {};
        data.pensions = [];
        data.ret = 0;
        for(var i = 0; i < pensions.length && i < pensionCount; i++) {
            var pension = pensions[i];
            var pensiondata = {};
            pensiondata.ret = 0;
            pensiondata.contribute = pension.contribute;
            pensiondata.isFinalSalary = pension.isFinalSalary;
            //pensiondata.pensionValue = $filter('StringToFloatFilter')(pension.pensionValue);
            if(pensiondata.contribute && !pensiondata.isFinalSalary) {

                pensiondata.annualSalary = $filter('StringToFloatFilter')(pension.annualSalary);
                
                pensiondata.employeeContribAmount = 12 * $filter('StringToFloatFilter')(pension.employeeContribAmount);
                pensiondata.ret += pensiondata.employeeContribAmount;

                pensiondata.employerContribAmount = 12 * $filter('StringToFloatFilter')(pension.employerContribAmount);
                pensiondata.ret += pensiondata.employerContribAmount;

                pensiondata.employeeContribPercentAmount = $filter('StringToFloatFilter')(pension.employeeContribPercentAmount);
                pensiondata.employeeContribPercentDec = pensiondata.employeeContribPercentAmount / 100.0;
                pensiondata.employeeContribPercentAmountCalc = pensiondata.employeeContribPercentDec * pensiondata.annualSalary;
                pensiondata.ret += pensiondata.employeeContribPercentAmountCalc;

                pensiondata.employerContribPercentAmount = $filter('StringToFloatFilter')(pension.employerContribPercentAmount);
                pensiondata.employerContribPercentDec = pensiondata.employerContribPercentAmount / 100.0;
                pensiondata.employerContribPercentAmountCalc = pensiondata.employerContribPercentDec * pensiondata.annualSalary;
                pensiondata.ret += pensiondata.employerContribPercentAmountCalc;
            }
            data.pensions.push(pensiondata);
            data.ret += pensiondata.ret;
        }
        //console.log(data);
        return data.ret;

    };

    this._pensionValueWithGrowth = function (user, growthrate) {
        var data = {};
        data.growthrate = growthrate;
        data.years = parseInt(user.pensionAge) - parseInt(user.age);
    	data.value = $filter('StringToFloatFilter')(user.pensionValue);
        data.contribs = this.totalPensionContributions(user.pensions, user.pensionCount);
        data.pensionValue = user.pensionValue;
        data.yeargrowths = [];
        data.total = data.pensionValue;
        for(var i = 0; i < data.years; i++) {
            var yeargrowth = {};
            yeargrowth.start = data.total;
            yeargrowth.contribs = data.contribs;
            yeargrowth.subtotal = yeargrowth.start + yeargrowth.contribs;
            yeargrowth.growth = yeargrowth.subtotal * data.growthrate;  
            yeargrowth.total = yeargrowth.subtotal + yeargrowth.growth;
            data.total = yeargrowth.total;
            data.yeargrowths.push(yeargrowth);
        }
        console.log(data);
        return data.total;

    };

    this.pensionValueWithGrowth = function (user) {
        return this._pensionValueWithGrowth(user,GROWTHRATE);

    };

    this.pensionValueWithGrowthBoost = function(user) {

        return this._pensionValueWithGrowth(user,BOOSTGROWTHRATE);

    };
	this.totalTaxFreeLumpSum = function(pensionValue) {
		
    	var value = $filter('StringToFloatFilter')(pensionValue);

		return value * 0.25;

	};
	this.totalMonthlyIncome = function(pensionValue) {
		
    	var value = $filter('StringToFloatFilter')(pensionValue);

        return (INCOMERATE * value) / 12;

	};


})

;


