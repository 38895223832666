'use strict';

angular.module('myApp.GDPRShieldDirective', [])

.directive('gdprshield', function () {

    return {
        restrict: 'A',
        replace: true,
        scope: {
        },
        templateUrl: '/ngviews/panels/gdpr-shield-directive.html',
        controller: function ($scope, $uibModal, $timeout, $document) {

            $scope.modalHowUseData = function() {
                var modal = $uibModal.open({
                    size: 'md',
                    controller: 'ModalCtrl',
                    appendTo: $document.find('.frame-content').eq(0),
                    templateUrl: '/ngviews/modals/how-we-use-gdpr.html'
                });
                $timeout(function(){
                    if(window.parent) {
                        window.parent.postMessage(["setLVPlannerScroll"], "*"); 
                    }
                });

            };


        }
    };

})

;