'use strict';

angular.module('myApp.ClientDetailsCtrl', ['ngRoute'])



.controller('ClientDetailsCtrl', ['$scope','$location','$anchorScroll','UserService', "ResultSelectionService", "$filter", "$timeout", "$uibModal", "$templateCache",
	function($scope, $location, $anchorScroll,UserService, ResultSelectionService, $filter, $timeout, $uibModal, $templateCache ) {

	UserService.clearSession();

	$scope.user = UserService.getUser();
	$scope.session = UserService.getSession();

    $scope.validationMessages = {
        "firstName" : "Please enter your first name",
        "surname" : "Please enter your surname",
        "gender" : "Please select",
        "age" : "Must be between 18-80",
        "ageMismatch" : "Pension age cannot be before current age",
        "pensionAge" : "Must be between 55-80"
    };

    $scope.ageFocus = false;
    $scope.hasAgeFocus = function(state) {
        $scope.ageFocus = state;
        //console.log('hasAgeFocus=' + $scope.ageFocus + " validateAges=" + $scope.validateAges());

    };

    $scope.showValidationErrorBlock = function () {

    	if(!$scope.validateAges()) {
    		return true;
    	}
    	//if(!$scope.validateContributions()) {
    	//	return true;
    	//}
    	if($scope.form.firstName.$invalid && !$scope.form.firstName.$pristine) {
    		return true;
    	}
    	if($scope.form.gender.$invalid && !$scope.form.gender.$pristine) {
    		return true;
    	}
    	if($scope.form.age.$invalid && !$scope.form.age.$pristine) {
    		return true;
    	}
    	if($scope.form.pensionAge.$invalid && !$scope.form.pensionAge.$pristine) {
    		return true;
    	}
    	return false;
    };


	$scope.showPension = function(i) {

		return parseInt($scope.user.pensionCount) > i;
	};

	$scope.totalPensionValue = function() {

		var ret = UserService.totalPensionValue();
        /*
        if(ret > 0) {
            $timeout(function(){
                $.fn.matchHeight._maintainScroll = true;
                $("[match-height=true]").matchHeight({});
            },1000);
        }
        */
        return ret;

	};

	$scope.validateAges = function () {
		if($scope.form.age.$pristine || $scope.form.pensionAge.$pristine) {
			return true;
		}

		if($scope.user.age > $scope.user.pensionAge) {
			//$scope.form.age.$invalid = true;
			//$scope.form.pensionAge.$invalid = true;
			//$scope.form.$valid = false;
			return false;
		} else {
			//$scope.form.age.$invalid = true;
			//$scope.form.pensionAge.$invalid = true;

		}
		return true;
	};

	$scope.validateContributions = function () {

		$scope.$broadcast('validateContributions', {pensionCount: $scope.user.pensionCount});

	}

	$scope.validate = function () {
		if ($scope.validateAges() && $scope.form.$valid) {
			return true;
		}
		//console.log($scope.form);
		angular.forEach($scope.form.$error, function(controls, errorName) {
			angular.forEach(controls, function(control) {
				control.$setDirty();
			});
		});		
		return false;
	}

    $scope.containsFinalSalaryPensions = function() {
        var finalSalaryPensions = [];                
        _.each($scope.user.pensions, function(o,i){
            if(o.isFinalSalary && parseInt($scope.user.pensionCount) > i) {
                finalSalaryPensions.push(i);
            }
        });
        return finalSalaryPensions.length > 0;
    }

	$scope.gotoNextPageValidated = function() {
        $scope.session.exitTool = ResultSelectionService.selectResultType();
		UserService.updateSession(function(){

	        $location.path('/contact-details/');
	        $anchorScroll();

		});
	};

	$scope.showFinalSalaryWarningModal = function () {
		UserService.hasShownFinalSalaryWarningModal();
		$templateCache.remove('/ngviews/modals/final-salary-warning.html');

        var modal = $uibModal.open({
        	size: 'md',
        	controller: 'FinalSalaryWarningModalCtrl',
        	scope: $scope,
            appendTo: $document.find('.frame-content').eq(0),
        	//templateUrl: '/ngviews/modals/need-help.html?time=' + (new Date().getTime())
        	templateUrl: '/ngviews/modals/final-salary-warning.html'
       	});

        $timeout(function(){
            if(window.parent) {
                window.parent.postMessage(["setLVPlannerScroll"], "*"); 
            }
        });

	};

    $scope.$watch('user.pensionCount', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $timeout(function(){
                $("[match-height=true]").matchHeight({});
            });
        }
    });


    $scope.$on('FinalSalaryWarningClosed', function () {
		$scope.gotoNextPageValidated();
    });

	$scope.gotoNextPage = function() {
		console.log('gotoNextPage');
        console.log($scope.validate());
        console.log($scope.form);
		if ($scope.validate()) {
			if($scope.containsFinalSalaryPensions() && UserService.neverShownFinalSalaryWarningModal()) {
				$scope.showFinalSalaryWarningModal();
			} else {
				$scope.gotoNextPageValidated();
			}
	    }
	};

    $timeout(function(){
        $("[match-height=true]").matchHeight({});
        if(window.parent) {
            window.parent.postMessage(["setLVPlannerScroll"], "*"); 
        }
    });

}]);


