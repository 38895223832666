'use strict';

angular.module('myApp.EmailUsAQuestionFormDirective', [])

.directive('emailusaquestionform', function (UserService,$timeout) {

    return {
        restrict: 'A',
        replace: true,
        scope: {
            user: '=user',
            session: '=session',
            usage: '@usage'
        },
        templateUrl: '/ngviews/forms/email-us-a-question-form-directive.html',
        link: function(scope, element, attr) {

        },
        controller: function ($scope) {

            $scope.forms = {};

            if($scope.details === undefined) {
                $scope.details = {
                    contactName : $scope.user.contactFirstName + ' ' + $scope.user.contactSurname,
                    // contactName : $scope.user.firstName,
                    contactEmail : $scope.user.contactEmail,
                    message : ""
                };
            }


            $scope.validateEmail = function () {
                var EMAIL_RE = /^\S+@\S+\.\S+$/;
                $scope.forms.form.contactEmail.$invalid = EMAIL_RE.test($scope.details.contactEmail) === false || $scope.details.contactEmail.length === 0;
                if($scope.forms.form.contactEmail.$invalid) {
                    return false;
                }
                return true;
            };

            $scope.validate = function () {
                if ($scope.validateEmail() && $scope.forms.form.$valid) {
                    return true;
                }
                //console.log($scope.forms.form);
                angular.forEach($scope.forms.form.$error, function(controls, errorName) {
                    angular.forEach(controls, function(control) {
                        control.$setDirty();
                    });
                });     
                return false;
            }

            $scope.isSubmitted = function() {
                if($scope.usage === "AccessResult" || $scope.usage === "PlannerResult" ) {
                    return $scope.session.ctaEmailUsAQuestionFormSubmitted;
                } else if($scope.usage === "NeedHelp") {
                    return $scope.session.needHelpEmailUsAQuestionFormSubmitted;
                } else if($scope.usage === "FinalSalary") {
                    return $scope.session.finalSalaryEmailUsAQuestionFormSubmitted;
                }
            }

            $scope.doEmailUsAQuestionForm = function(){

                if ($scope.validate()) {

                    if($scope.usage === "AccessResult" || $scope.usage === "PlannerResult" ) {
                        $scope.user.ctaType = "Email Question";
                        $scope.user.ctaContactName = $scope.details.contactFirstName + ' ' + $scope.details.contactSurname;
                        $scope.user.ctaContactEmail = $scope.details.contactEmail;
                        $scope.user.ctaMessage = $scope.details.message;
                    } else if($scope.usage === "NeedHelp") {
                        $scope.user.needHelpType = "Email Question";
                        $scope.user.needHelpContactName = $scope.details.contactFirstName + ' ' + $scope.details.contactSurname;
                        $scope.user.needHelpContactEmail = $scope.details.contactEmail;
                        $scope.user.needHelpMessage = $scope.details.message;
                    } else if($scope.usage === "FinalSalary") {
                        $scope.user.finalSalaryType = "Email Question";
                        $scope.user.finalSalaryContactName = $scope.details.contactFirstName + ' ' + $scope.details.contactSurname;
                        $scope.user.finalSalaryContactEmail = $scope.details.contactEmail;
                        $scope.user.finalSalaryMessage = $scope.details.message;
                    }



                    UserService.updateSession(function(){

                        $timeout(function(){
                            UserService.boostBlend(function(){

                                UserService.completeDataCapture($scope.usage, function(){});

                                if($scope.usage === "AccessResult" || $scope.usage === "PlannerResult" ) {
                                    $scope.session.ctaEmailUsAQuestionFormSubmitted = true;
                                } else if($scope.usage === "NeedHelp") {
                                    $scope.session.needHelpEmailUsAQuestionFormSubmitted = true;
                                } else if($scope.usage === "FinalSalary") {
                                    $scope.session.finalSalaryEmailUsAQuestionFormSubmitted = true;
                                }
                                
                            });
                        });



                    });
                }
            };

        }
    };

})

;