

(function ($) {

    $(function () {

        //$('.pdf').find("[match-height=true]").matchHeight({});
        //$('.pdf').find("[match-height1=true]").matchHeight({});
        //$('.pdf').find("[match-height2=true]").matchHeight({});
        //$('.pdf').find("[match-height3=true]").matchHeight({});
        //$('.pdf').find("[match-height4=true]").matchHeight({});
        //$('.pdf').find("[match-height5=true]").matchHeight({});
        //$('.pdf').find("[match-height6=true]").matchHeight({});

    });




}(jQuery));





