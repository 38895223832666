'use strict';

angular.module('myApp.LandingCtrl', ['ngRoute'])



.controller('LandingCtrl', ['$scope','$templateCache','$location','$anchorScroll','UserService','$sessionStorage','$timeout',
	function($scope, $templateCache, $location, $anchorScroll, UserService,$sessionStorage,$timeout ) {

    if($sessionStorage === undefined) {
        $scope.supported = false;
    } else {
        try {
            $sessionStorage.empty();        
        } catch(err) {}
    }

	//$templateCache.removeAll();
	UserService.clearUser();	
	UserService.clearSession();	


	$scope.session = UserService.getSession();
    $scope.user = UserService.getUser();

    $scope.gotoClientDetailsForm = function () {
        $location.path('/client-details/');
        $anchorScroll();
    };

    $scope.supported = true;

    $timeout(function(){

        var locationsearch = $location.search();
        console.log('locationsearch',locationsearch);
        var campaign = UserService.getCampaign();
        UserService.setCampaign(locationsearch);

    },10);

    $timeout(function(){
        $("[match-height=true]").matchHeight({});
        if(window.parent) {
            window.parent.postMessage(["setLVPlannerScroll"], "*"); 
        }
    },500);



}]);


