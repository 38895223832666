'use strict';

angular.module('myApp.ModalCtrl', [])

.controller('ModalCtrl', ['$scope', '$uibModalInstance', '$timeout', 
	function($scope, $uibModalInstance, $timeout) {

	$scope.$hide = function () {
		$('.panel-can-have-assumptions-modal').removeClass('panel-with-assumptions-modal');
		$uibModalInstance.close();
	};


}]);

