'use strict';

angular.module('myApp.AccessResultBlendedContentDirective', [])

.directive('accessresultblendedcontent', function (ChatAvailabilityService,UserService,$timeout, $uibModal) {

    return {
        restrict: 'A',
        replace: true,
        scope: {
            user: '=user',
            session: '=session'
        },
        templateUrl: '/ngviews/access-result/blended-content-directive.html',
        link: function(scope, element, attr) {

        },
        controller: function ($scope) {



            $scope.showRequestACallBackForm = function(){

                $scope.session.shownCtaRequestACallBackForm = true;
                $scope.session.shownCtaEmailUsAQuestionForm = false;

            };

            $scope.showEmailUsAQuestionForm = function(){

                $scope.session.shownCtaRequestACallBackForm = false;
                $scope.session.shownCtaEmailUsAQuestionForm = true;
                
            };

            $scope.showCTAButtons = function() {

                return !$scope.session.ctaRequestACallBackFormSubmitted && !$scope.session.ctaEmailUsAQuestionFormSubmitted;
            };

            $scope.canShowChat = function() {

                return ChatAvailabilityService.canShowChat();
            }




        }
    };

})

;