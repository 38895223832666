'use strict';

angular.module('myApp.ResultSelectionService', [])

.service('ResultSelectionService', function (UserService) {

    var self = this;

    this.selectResultType = function () {

    	var user = UserService.getUser();
    	if(parseInt(user.age) + 2 < parseInt(user.pensionAge)) {
    		return "planner";
    	}
    	return "access";

    };


})

;


