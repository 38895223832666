'use strict';

angular.module('myApp.FinalSalaryWarningModalCtrl', [])

.controller('FinalSalaryWarningModalCtrl', ['$scope', '$uibModalInstance', '$timeout', 
	function($scope, $uibModalInstance, $timeout) {

	$scope.okGotIt = function () {
        $scope.gotoNextPageValidated();
		$uibModalInstance.close();


	};


}]);

