'use strict';

angular.module('myApp.UserService', [])

.service('UserService', function ($sessionStorage, $filter, PersistanceService) {

    var self = this;

    this.boostBlend = function (callback) {

    	var data = {
    		user : $sessionStorage.user,
            campaign : this.getCampaign()
    	};
    	if($sessionStorage.session !== undefined) {
    		data.session = $sessionStorage.session;
    		console.log('boostBlend session 1',$sessionStorage.session);
    	}
    	PersistanceService.boostBlend(data).then(function(response){

    		$sessionStorage.session.sessionToken = response.data.sessionToken;
    		$sessionStorage.session.sessionId = response.data.sessionId;
            console.log('boostBlend session 2',$sessionStorage.session);

    		callback();
        });

    }

    this.hasShownFinalSalaryWarningModal = function() {
    	$sessionStorage.shownFinalSalaryWarningModal = true; 
    }

    this.neverShownFinalSalaryWarningModal = function() {
    	//$ missing - causes cross domain iframe issue
    	return !$sessionStorage.shownFinalSalaryWarningModal; 
    }

    this.completeDataCapture = function (usage, callback) {

    	var data = {
    		id : $sessionStorage.session.sessionId,
    		sessiontoken : $sessionStorage.session.sessionToken
    	};
    	console.log(data);
    	PersistanceService.completeDataCapture(usage, data).then(function(response){

    		callback();
        });

    }

    this.updateSession = function (callback) {
    	
    	var data = {
    		user : $sessionStorage.user,
            campaign : this.getCampaign()
    	};
    	if($sessionStorage.session !== undefined) {
    		data.session = $sessionStorage.session;
    		console.log('updateSession session 1',$sessionStorage.session);
    	}

    	/*
        PersistanceService.updateSession(data).then(function(response){

    		$sessionStorage.session.sessionToken = response.data.sessionToken;
    		$sessionStorage.session.sessionId = response.data.sessionId;
            console.log('updateSession session 2',$sessionStorage.session);

    		callback();
        });
        */
        callback();
    }

    this.updateSessionAndPersist = function (callback) {
    	
    	var data = {
    		user : $sessionStorage.user,
            campaign : this.getCampaign()
    	};
    	if($sessionStorage.session !== undefined) {
    		data.session = $sessionStorage.session;
    		console.log('updateSession session 1',$sessionStorage.session);
    	}

        PersistanceService.updateSession(data).then(function(response){

    		$sessionStorage.session.sessionToken = response.data.sessionToken;
    		$sessionStorage.session.sessionId = response.data.sessionId;
            console.log('updateSession session 2',$sessionStorage.session);

    		callback();
        });
        callback();
    }

    this.clearSession = function() {
    	$sessionStorage.session = {
    		sessionToken : undefined,
    		exitTool : "",
    		entryTool : ($.url().param('type') !== undefined ? $.url().param('type') : 'unknown'),

    	};
    	var user = angular.copy(this.getUser());
    	user.initialContactEmailAt = 0;
    	user.ctaSentAt = 0;
		user.ctaType = "";
		user.ctaContactName = "";
		user.ctaContactEmail = "";
		user.ctaContactTelephone = "";
		user.ctaRequestACallBackBestDayToCall = "";
		user.ctaRequestACallBackBestTimeToCall = "";
		user.ctaMessage = "";

    	user.needHelpSentAt = 0;
		user.needHelpType = "";
		user.needHelpContactName = "";
		user.needHelpContactEmail = "";
		user.needHelpContactTelephone = "";
		user.needHelpRequestACallBackBestDayToCall = "";
		user.needHelpRequestACallBackBestTimeToCall = "";
		user.needHelpMessage = "";

    	user.finalSalarySentAt = 0;
		user.finalSalaryType = "";
		user.finalSalaryContactName = "";
		user.finalSalaryContactEmail = "";
		user.finalSalaryContactTelephone = "";
		user.finalSalaryRequestACallBackBestDayToCall = "";
		user.finalSalaryRequestACallBackBestTimeToCall = "";
		user.finalSalaryMessage = "";

		user.access = this.initAccess();
		user.planner = this.initPlanner();
		$sessionStorage.user = user;
    	//console.log($sessionStorage.session);
    };

    this.getCampaign = function () {
        if($sessionStorage.campaign) {
            return $sessionStorage.campaign;
        }
        return {};
    }

    this.setCampaign = function (campaign) {
        $sessionStorage.campaign = campaign;
    }

    this.getSession = function () {
    	return $sessionStorage.session;
    };

    this.clearUser = function() {
    	$sessionStorage.user = undefined;
    };

    this.initAccess = function () {
    	return {
			pensionValueAfterTaxFreeLumpSum : "",
			statePensionIncome : "",
			taxFreeLumpSum : "",
			guaranteedIncome : "",
			drawdownIncome : "",
			fixedIncome : "",
			drawdownIncomeBlended : "",
			fixedIncomeBlended : "",
			totalIncomeBlended : ""

		};
    };

    this.initPlanner = function () {
		return {
			pensionValueWithGrowth : "",
			pensionValueWithGrowthBoosted : "",
			pensionValueWithGrowthAfterTaxFreeLumpSum : "",
			pensionValueWithGrowthAfterTaxFreeLumpSumBoosted : "",
			taxFreeLumpSum : "",
			taxFreeLumpSumBoosted : "",
			monthlyIncome : "",
			monthlyIncomeBoosted : "",
			
		};
    };

    this.getUser = function(){

    	if($sessionStorage.user === undefined) {
			$sessionStorage.user = {
				contactName : "",
				contactTelephone : "",
				contactEmail : "",
				initialContactEmailAt : 0,

				ctaSentAt : 0,
				ctaType : "",
				ctaContactName : "",
				ctaContactEmail : "",
				ctaContactTelephone : "",
				ctaRequestACallBackBestDayToCall : "",
				ctaRequestACallBackBestTimeToCall : "",
				ctaMessage : "",

				needHelpSentAt : 0,
				needHelpType : "",
				needHelpType : "",
				needHelpContactName : "",
				needHelpContactEmail : "",
				needHelpContactTelephone : "",
				needHelpRequestACallBackBestDayToCall : "",
				needHelpRequestACallBackBestTimeToCall : "",
				needHelpMessage : "",

				finalSalarySentAt : 0,
				finalSalaryType : "",
				finalSalaryContactName : "",
				finalSalaryContactEmail : "",
				finalSalaryContactTelephone : "",
				finalSalaryRequestACallBackBestDayToCall : "",
				finalSalaryRequestACallBackBestTimeToCall : "",
				finalSalaryMessage : "",

//				optOutMarketing : false,
                optInEmail : false,
                optInPost : false,
                optInSMS : false,
                optInPhone : false,


				firstName : "",
				gender : "",
				age : "",
				pensionAge : "",
				pensionCount : 0,
				pensions : [], 
				pensionValue : "",
				access : this.initAccess(),
				planner : this.initPlanner()
			};
			for(var i=0;i<=10;i++) {
				var pension = {};
				pension.pensionValue = "";
				pension.contribute = false;
				pension.isFinalSalary = false;
				pension.employeeContribAmount = "";
				pension.employeeContribPercentAmount = "";
				pension.employerContribAmount = "";
				pension.employerContribPercentAmount = "";
				pension.annualSalary = "";
				$sessionStorage.user.pensions.push(pension);
			}
    	} 

    	return $sessionStorage.user;

    };

	this.totalPensionValue = function() {

		var user = this.getUser();

		var ret = 0;
		_.each(user.pensions, function(o, i){
			if(user.pensionCount > i) {
				if(!o.isFinalSalary) {
					ret += $filter('StringToFloatFilter')(o.pensionValue);
				}
			}
		});
		user.pensionValue = ret;
		return user.pensionValue;

	};




})

;


