'use strict';

angular.module('myApp.InputFormatDirective', [])
/*
 * Display the input format in currency form once the user leaves an input
 */
.directive('inputformat', function ($filter, $timeout) {

    return {
        require: '?ngModel',
        link: function (scope, element, attrs, ngModel) {

            $timeout(function () {

                if (attrs.inputformat === 'currency') {

                    // Sanitise values
                    element.ready(function () {
                        var val = element.val();
                        if (val.length > 0) {
                            val = val.replace(/[^\d.]/g, '');
                            val = parseFloat(val);
                            // update the element value
                            if(Math.floor(val) == val) {
                                element.val($filter('number')(val));
                            } else {
                                element.val($filter('currency')(val, ""));
                            }
                        }
                    });

                    // Strip all non numeric chars
                    element.on('keyup', function () {
                        var val = element.val();
                        if (val.length > 0 && val.match(/[^\d.]/g)) {
                            val = val.replace(/[^\d.]/g, '');
                            element.val(val);
                            element.trigger('input');
                        }
                        // Is value over the max value specified
                        if (parseFloat(val) > attrs.max) {
                            val = attrs.max;
                            element.val(val);
                            element.trigger('input');
                        }
                    });


                    // Clear the currency format within the input for editing
                    element.on('focus', function () {
                        var val = element.val();
                        // Remove the pound if present
                        val = val.replace(/,/g, '');
                        // update the element value
                        element.val(val);
                    });
                    // Add the currency formatting once the user has finished editing
                    element.on('blur', function () {

                        var val = element.val();

                        if (val <= 0) {
                            val = '0';
                        }

                        element.val(val);
                        //element.trigger('input');
                        ngModel.$setViewValue(val);
                        ngModel.$render();

                    });

                    element.on('focusout', function () {

                        var val = element.val();
                        // Remove the pound if present
                        if (val[0] === '£') {
                            val = val.substring(1);
                        }
                        // Check if the value is a number
                        if (isNaN(val) || val.length === 0) {
                            val = '';
                        }
                        // Is value under the min value specified
                        if (parseFloat(val) < attrs.min && parseFloat(val) !== 0) {
                            val = attrs.min;
                            element.val(val);
                            element.trigger('input');
                        }

                        if (parseFloat(val) > attrs.max) {
                            console.log('blur max');
                            val = attrs.max;
                            element.val(val);
                            element.trigger('input');
                        }

                        if (val === '') {
                            element.val('');
                        } else {
                            // update the element value
                            //element.val($filter('number')(val));
                            if(Math.floor(val) == parseFloat(val)) {
                                element.val($filter('number')(val));
                            } else {
                                element.val($filter('currency')(val, ""));
                            }
                        }

                        //element.trigger('input');

                    });

                } else if (attrs.inputformat === 'number') {

                    element.on('blur', function () {

                        var val = element.val();
                        if (isNaN(val)) {
                            val = '';
                        }

                        if (attrs.prefix === 'year' && val.length === 2) {
                            val = '19' + val;
                        }
                        ngModel.$setViewValue(val);
                        element.val(val);
                    });

                    // Strip all non numeric chars
                    element.on('keyup', function () {
                        var val = element.val();
                        if (attrs.maxlength !== undefined && val.length === parseFloat(attrs.maxlength)) {
                            if (isNaN(val)) {
                                val = '';
                            }
                            // Is value over the max value specified
                            if (parseFloat(val) > parseFloat(attrs.max)) {
                                val = attrs.max;
                                element.val(val);
                            } else if (parseFloat(val) < attrs.min) {
                                val = attrs.min;
                                element.val(val);
                            }
                        }

                    });

                } else if (attrs.inputformat === 'telephone') {


                    element.on('blur', function () {

                        $timeout(function () {
                            var val = ""+element.val();



                            val = val.replace(/[^0-9\s\+]/g,'');

                            var validateval = val.replace(/[^0-9\+]/g,'');

                            var allowzero = attrs.allowzero !== undefined && attrs.allowzero;
                            //console.log(validateval.length);
                            console.log('allowzero='+allowzero);

                            ngModel.$setViewValue(val);
                            ngModel.$setValidity('min', validateval.length >= 11 || (allowzero && validateval.length === 0));

                            element.val(val);

                        },10);
                    });

                    element.on('keyup', function () {
                        var val = element.val();
                        val = val.replace(/[^0-9\s\+]/g,'');
                        //ngModel.$setViewValue(val);
                        element.val(val);

                    });

                } else if (attrs.inputformat === 'numberminmax') {

                    element.on('focus', function () {

                        $timeout(function () {

                            var val = element.val();

                            val = val.replace(/[^\d.]/g, '');

                            if (isNaN(val)) {
                                val = '';
                            }

                            element.val($filter('number')(Math.round(parseFloat(val) * 100) / 100));

                            // Is value over the max value specified
                            if (parseFloat(val) > parseFloat(attrs.min)) {
                                ngModel.$setValidity('min', true);
                            } else if (parseFloat(val) < parseFloat(attrs.min)) {
                                //element.val($filter('number')(attrs.min));
                                ngModel.$setValidity('min', false);
                            } else {
                                ngModel.$setValidity('min', true);
                            }

                            if (parseFloat(val) < parseFloat(attrs.max)) {
                                ngModel.$setValidity('max', true);
                            } else if (parseFloat(val) > parseFloat(attrs.max)) {
                                //element.val($filter('number')(attrs.max));
                                ngModel.$setValidity('max', false);
                            } else {
                                ngModel.$setValidity('max', true);
                            }
                        }, 10);
                    });


                    element.on('blur', function () {

                        var val = element.val();

                        val = val.replace(/[^\d.]/g, '');

                        if (isNaN(val)) {
                            val = '';
                        }

                        element.val($filter('number')(Math.round(parseFloat(val) * 100) / 100));

                        // Is value over the max value specified
                        if (parseFloat(val) > parseFloat(attrs.min)) {
                            ngModel.$setValidity('min', true);
                        } else if (parseFloat(val) < parseFloat(attrs.min)) {
                            //element.val($filter('number')(attrs.min));
                            ngModel.$setValidity('min', false);
                        } else {
                            ngModel.$setValidity('min', true);
                        }

                        if (parseFloat(val) < parseFloat(attrs.max)) {
                            ngModel.$setValidity('max', true);
                        } else if (parseFloat(val) > parseFloat(attrs.max)) {
                            //element.val($filter('number')(attrs.max));
                            ngModel.$setValidity('max', false);
                        } else {
                            ngModel.$setValidity('max', true);
                        }
                    });

                    // Strip all non numeric chars
                    element.on('keyup', function () {
                        var val = element.val();

                        val = val.replace(/[^\d.]/g, '');

                        if (isNaN(val)) {
                            val = '';
                        }

                        /*
                        if (parseFloat(val) < parseFloat(attrs.max)) {
                            ngModel.$setValidity('max', true);
                        } else if (parseFloat(val) > parseFloat(attrs.max)) {
                            element.val($filter('number')(attrs.max));
                        } else {
                            ngModel.$setValidity('max', true);
                        }
                        */

                    });

                }

            }, 10);
        }

    };

})

;