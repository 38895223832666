'use strict';

angular.module('myApp.NeedHelpModalCtrl', [])

.controller('NeedHelpModalCtrl', ['$scope', '$uibModalInstance', '$timeout', 'UserService', 'ChatAvailabilityService',
	function($scope, $uibModalInstance, $timeout, UserService, ChatAvailabilityService) {

	$scope.user = UserService.getUser();
	$scope.session = UserService.getSession();

	$scope.$hide = function () {
	    $('.panel-can-have-help-modal').removeClass('panel-with-help-modal');
		$uibModalInstance.close();
	};

	$scope.canShowChat = function() {

		return ChatAvailabilityService.canShowChat();
	}


	$scope.showingRequestCallBack = false;
	$scope.showRequestCallBack = function() {
		$scope.showingRequestCallBack = true;
	}


}]);

