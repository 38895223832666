'use strict';

angular.module('myApp.AccessResultCtrl', ['ngRoute'])



.controller('AccessResultCtrl', ['$scope','$location','$anchorScroll','UserService', 'AccessService', '$filter', '$timeout', '$uibModal', '$document',
	function($scope, $location, $anchorScroll,UserService, AccessService, $filter, $timeout, $uibModal, $document ) {

	$scope.user = UserService.getUser();
	$scope.session = UserService.getSession()

	$scope.user.access.taxFreeLumpSum = AccessService.totalTaxFreeLumpSum($scope.user.pensionValue);
	$scope.user.access.pensionValueAfterTaxFreeLumpSum = AccessService.pensionPotAfterTaxFreeLumpSum($scope.user.pensionValue);

	$scope.user.access.statePensionIncome = AccessService.calcStatePensionIncome();


	$scope.user.access.guaranteedIncome = AccessService.guaranteedIncome($scope.user.access.pensionValueAfterTaxFreeLumpSum, $scope.user.pensionAge);
	$scope.user.access.drawdownIncome = AccessService.drawdownIncome($scope.user.access.pensionValueAfterTaxFreeLumpSum);
	$scope.user.access.fixedIncome = AccessService.fixedIncome($scope.user.access.pensionValueAfterTaxFreeLumpSum, $scope.user.pensionAge);
	$scope.user.access.drawdownIncomeBlended = AccessService.drawdownIncomeBlended($scope.user.access.pensionValueAfterTaxFreeLumpSum);
	$scope.user.access.fixedIncomeBlended = AccessService.fixedIncomeBlended($scope.user.access.pensionValueAfterTaxFreeLumpSum, $scope.user.pensionAge);
	$scope.user.access.totalIncomeBlended = AccessService.totalIncomeBlended($scope.user.access.drawdownIncomeBlended, $scope.user.access.fixedIncomeBlended);

	$scope.blended = false;
	UserService.updateSession(function(){});

    $scope.gotoBack = function () {
        $location.path('/client-details/');
        $anchorScroll();
    };

	$scope.doBlend = function () {
        $scope.blended = true;
        $timeout(function(){
            $("[match-height=true]").matchHeight({});
            $("[match-height2=true]").matchHeight({});
            $("[match-height4=true]").matchHeight({});
            $("[match-height3=true]").matchHeight({});
            $("[match-height5=true]").matchHeight({});
            $("[match-height6=true]").matchHeight({});
        });
        UserService.boostBlend(function(){});
	};

    $timeout(function(){
        $scope.doBlend();
    });

    $scope.modalAnnuityAssumptions = function(){
        var modal = $uibModal.open({
            size: 'md',
            appendTo: $document.find('.frame-content').eq(0),
            controller: 'ModalCtrl',
            templateUrl: '/ngviews/modals/access-annuity-assumptions.html'
        });

        $timeout(function(){
            if(window.parent) {
                window.parent.postMessage(["setLVPlannerScroll"], "*"); 
            }
        });
        
    };

    $scope.modalDrawdownAssumptions = function(){
        var modal = $uibModal.open({
            size: 'md',
            appendTo: $document.find('.frame-content').eq(0),
            controller: 'ModalCtrl',
            templateUrl: '/ngviews/modals/access-drawdown-assumptions.html'
        });

        $timeout(function(){
            if(window.parent) {
                window.parent.postMessage(["setLVPlannerScroll"], "*"); 
            }
        });
        
    };

    $scope.modalFTAAssumptions = function(){
        var modal = $uibModal.open({
            size: 'md',
            appendTo: $document.find('.frame-content').eq(0),
            controller: 'ModalCtrl',
            templateUrl: '/ngviews/modals/access-fta-assumptions.html'
        });

        $timeout(function(){
            if(window.parent) {
                window.parent.postMessage(["setLVPlannerScroll"], "*"); 
            }
        });
        
    };


    $scope.modalBlendedAssumptions = function(){
        var modal = $uibModal.open({
            size: 'md',
            appendTo: $document.find('.frame-content').eq(0),
            controller: 'ModalCtrl',
            templateUrl: '/ngviews/modals/access-blended-assumptions.html'
        });

        $timeout(function(){
            if(window.parent) {
                window.parent.postMessage(["setLVPlannerScroll"], "*"); 
            }
        });
        
    };










    $timeout(function(){
		$("[match-height=true]").matchHeight({});
        if(window.parent) {
            window.parent.postMessage(["setLVPlannerScroll"], "*"); 
        }
    });


}]);


