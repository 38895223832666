'use strict';

angular.module('myApp.GDPRShieldDirective', [])

.directive('gdprshield', function () {

    return {
        restrict: 'A',
        replace: true,
        scope: {
        },
        templateUrl: '/ngviews/panels/gdpr-shield-directive.html',
        controller: function ($scope) {



        }
    };

})

;