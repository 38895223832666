'use strict';

angular.module('myApp.PensionDetailsDirective', [])

.directive('pensiondetails', function ($filter,ChatAvailabilityService) {

    return {
        require: '^form',
        restrict: 'A',
        replace: true,
        scope: {
            session: '=session',
            user: '=user',
            pension: '=pension',
            form: '=form',
            index: '@index'
        },
        templateUrl: '/ngviews/client-details/pension-details-directive.html',
        controller: function ($scope) {

            /*
            $scope.employeeContribAmountCalculated = 0;
            $scope.doEmployeeContribAmountCalculated = function () {
                var employeeContribPercentAmount = $filter('StringToFloatFilter')($scope.pension.employeeContribPercentAmount);
                var annualSalary = $filter('StringToFloatFilter')($scope.pension.annualSalary);
                $scope.employeeContribAmountCalculated = ((employeeContribPercentAmount / 100.0 ) * annualSalary) / 12;
                $scope.employeeContribAmountCalculated = $filter('currency')($scope.employeeContribAmountCalculated, "");

            }
            $scope.doEmployeeContribAmountCalculated();
            $scope.employerContribAmountCalculated = 0;
            $scope.doEmployerContribAmountCalculated = function () {
                
                var employerContribPercentAmount = $filter('StringToFloatFilter')($scope.pension.employerContribPercentAmount);
                var annualSalary = $filter('StringToFloatFilter')($scope.pension.annualSalary);
                $scope.employerContribAmountCalculated = ((employerContribPercentAmount / 100.0 ) * annualSalary) / 12;
                $scope.employerContribAmountCalculated = $filter('currency')($scope.employerContribAmountCalculated, "");

            }
            $scope.doEmployerContribAmountCalculated();

            $scope.$watch('pension.annualSalary', function (newValue, oldValue) {
                if (newValue !== oldValue) {

                    $scope.doEmployeeContribAmountCalculated();
                    $scope.doEmployerContribAmountCalculated();

                }
            });

            $scope.$watch('pension.employeeContribPercentAmount', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.doEmployeeContribAmountCalculated();

                }
            });

            $scope.$watch('pension.employerContribPercentAmount', function (newValue, oldValue) {
                if (newValue !== oldValue) {

                    $scope.doEmployerContribAmountCalculated();

                }
            });
            */

            $scope.isOnlyFinalSalaryPensions = function() {
                var finalSalaryPensions = [];                
                _.each($scope.user.pensions, function(o,i){
                    if(o.isFinalSalary && parseInt($scope.user.pensionCount) > i) {
                        finalSalaryPensions.push(i);
                    }
                });
                return $scope.user.pensionCount === finalSalaryPensions.length;
            }

            $scope.isFirstFinalSalary = function(index) {

                var finalSalaryPensions = [];                
                _.each($scope.user.pensions, function(o,i){
                    if(o.isFinalSalary && parseInt($scope.user.pensionCount) > i) {
                        finalSalaryPensions.push(i);
                    }
                });
                return finalSalaryPensions.length > 0 && finalSalaryPensions[0] === parseInt(index);
            }


            $scope.employeeContribAmountIsReadOnly = function() {

                var employeeContribPercentAmount = $filter('StringToFloatFilter')($scope.pension.employeeContribPercentAmount);
                return employeeContribPercentAmount > 0;

            };

            $scope.employeeContribPercentAmountIsReadOnly = function() {

                var employeeContribAmount = $filter('StringToFloatFilter')($scope.pension.employeeContribAmount);
                return employeeContribAmount > 0;

            };




            $scope.employerContribAmountIsReadOnly = function() {

                var employerContribPercentAmount = $filter('StringToFloatFilter')($scope.pension.employerContribPercentAmount);
                return employerContribPercentAmount > 0;

            };

            $scope.employerContribPercentAmountIsReadOnly = function() {

                var employerContribAmount = $filter('StringToFloatFilter')($scope.pension.employerContribAmount);
                return employerContribAmount > 0;

            };

            $scope.isSalaryRequired = function () {
                var employerContribPercentAmount = $filter('StringToFloatFilter')($scope.pension.employerContribPercentAmount);
                if(isNaN(employerContribPercentAmount)) {
                    employerContribPercentAmount = 0;
                }
                var employeeContribPercentAmount = $filter('StringToFloatFilter')($scope.pension.employeeContribPercentAmount);
                if(isNaN(employeeContribPercentAmount)) {
                    employeeContribPercentAmount = 0;
                }
                return (employerContribPercentAmount > 0 || employeeContribPercentAmount > 0);
            }

            $scope.hasMissingSalary = function() {
                var ret = false;
                if($scope.isSalaryRequired()) {
                    //if(!$scope.form['annualSalary'+$scope.index].$pristine) {
                        var annualSalary = $filter('StringToFloatFilter')($scope.pension.annualSalary);
                        ret = (annualSalary === 0 || isNaN(annualSalary));
                        
                        //console.log($scope.form);
                    //}
                }
                $scope.form["annualSalary"+$scope.index].$setValidity("SalaryProvided", !ret);
                return ret;
            };

            $scope.$on('validateContributions', function (event, args) {
                if(args.pensionCount > $scope.index) {
                    $scope.hasMissingSalary();
                }
            });

            $scope.canShowChat = function() {

                return ChatAvailabilityService.canShowChat();
            }

            $scope.showRequestACallBackForm = function(){

                $scope.session.shownFinalSalaryRequestACallBackForm = true;
                $scope.session.shownFinalSalaryEmailUsAQuestionForm = false;

            };

            $scope.showEmailUsAQuestionForm = function(){

                $scope.session.shownFinalSalaryRequestACallBackForm = false;
                $scope.session.shownFinalSalaryEmailUsAQuestionForm = true;
                
            };

        }
    };

})

;