'use strict';

angular.module('myApp.PersistanceService', [])

.service('PersistanceService', ['$http', function ($http) {

    var self = this;


    this.boostBlend = function(data, response) {

        var config = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: data,
             url: '/api/lvplanner/boostblend'
        };

        return $http(config).then(
            function(response) {
                if (response.status === 200) {
                    console.log(response.data);
                    return response;
                }
            },
            function(response) {
                return response;
            }
        );
    };

    this.completeDataCapture = function(usage, data, response) {

        var url = '/api/lvplanner/';
        if(usage === "AccessResult" || usage === "PlannerResult" ) {
            url += "completecta";
        } else if(usage === "NeedHelp") {
            url += "completeneedhelp";
        } else if(usage === "FinalSalary") {
            url += "completefinalsalary";
        }


        var config = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: data,
             url: url
        };

        return $http(config).then(
            function(response) {
                if (response.status === 200) {
                    console.log(response.data);
                    return response;
                }
            },
            function(response) {
                return response;
            }
        );
    };

    this.updateSession = function(data, response) {

        var config = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: data,
             url: '/api/lvplanner/updatesession'
        };

        return $http(config).then(
            function(response) {
                if (response.status === 200) {
                    console.log(response.data);
                    return response;
                }
            },
            function(response) {
                return response;
            }
        );
    };


}])

;


