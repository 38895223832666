'use strict';

angular.module('myApp.ContactDetailsCtrl', ['ngRoute'])



.controller('ContactDetailsCtrl', ['$scope','$location','$anchorScroll','UserService','ResultSelectionService', "$filter", "$uibModal", "$timeout", "$document",
	function($scope, $location, $anchorScroll,UserService, ResultSelectionService, $filter, $uibModal, $timeout, $document ) {

	$scope.user = UserService.getUser();
    $scope.session = UserService.getSession();
    $scope.MIN_TELEPHONE_LENGTH = 11;

    $scope.validationMessages = {
        "contactEmail" : "Please enter your email address",
        "contactTelephone" : "Telephone number must at least " + $scope.MIN_TELEPHONE_LENGTH + " digits",
        "contactTelephoneTooShort" : "Telephone number must at least " + $scope.MIN_TELEPHONE_LENGTH + " digits"
    };


    $scope.gotoBack = function () {
        $location.path('/client-details/');
        $anchorScroll();
    };

    $scope.focus = [];
    $scope.hasFocus = function ($field) {
        var ret = _.find($scope.focus, function(o){
            return o === $field.$name;
        });
        //console.log(ret);
        return ret;
    }

    $scope.doFocus = function ($field) {
        $scope.focus.push($field.$name);
        //console.log($scope.focus);
    };

    $scope.doBlur = function ($field) {
        $scope.focus = _.reject($scope.focus, function(o){
            return o === $field.$name;
        });
        //console.log($scope.focus);
        //$scope.form.contactTelephone.$setValidity("TooShort", (""+$scope.user.contactTelephone).length >= $scope.MIN_TELEPHONE_LENGTH);
    };

    $scope.getTelephoneError = function () {
        if($scope.form.contactTelephone.$error.TooShort) {
            return $scope.validationMessages["contactTelephoneTooShort"];
        }
        return $scope.validationMessages["contactTelephone"]
    }

    $scope.validate = function () {

        //$scope.form.contactTelephone.$setValidity("TooShort", (""+$scope.user.contactTelephone).length >= $scope.MIN_TELEPHONE_LENGTH);
        console.log($scope.form);

        if ($scope.validateEmail() && $scope.validateTelephone() && $scope.form.$valid) {
            return true;
        }
        angular.forEach($scope.form.$error, function(controls, errorName) {
            angular.forEach(controls, function(control) {
                control.$setDirty();
            });
        });     
        return false;
    };

    $scope.showMandatoryAlert = function () {
        if($scope.focus.length > 0) {
            return false;
        }
        //if($scope.form.contactTelephone.$invalid && !$scope.form.contactTelephone.$pristine) {
        //    return true;
        //}
        var ret = false;
        if((!$scope.validateEmail() || $scope.form.contactEmail.$invalid) && !$scope.form.contactEmail.$pristine) {
            ret = true;
        }
        if((!$scope.validateTelephone() || $scope.form.contactTelephone.$invalid) && !$scope.form.contactTelephone.$pristine) {
            ret = true;
        }
        return ret;
    }

    $scope.validateTelephone = function () {
        if($scope.form.contactTelephone.$pristine) {
            return true;
        }
        $scope.form.contactTelephone.$invalid = $scope.user.contactTelephone.length > 0 && $scope.user.contactTelephone.length < $scope.MIN_TELEPHONE_LENGTH;
        if($scope.form.contactTelephone.$invalid) {
            return false;
        }
        return true;
    };

    $scope.validateEmail = function () {
        if($scope.form.contactEmail.$pristine) {
            return true;
        }
        var EMAIL_RE = /^\S+@\S+\.\S+$/;
        $scope.form.contactEmail.$invalid = EMAIL_RE.test($scope.user.contactEmail) === false || $scope.user.contactEmail.length === 0;
        if($scope.form.contactEmail.$invalid) {
            return false;
        }
        return true;
    };

	$scope.gotoNextPage = function() {
        if ($scope.validate()) {

            UserService.updateSession(function(){

                if($scope.session.exitTool === "planner") {
                    $location.path('/planner-result/');
                }
                else if($scope.session.exitTool === "access") {
                    $location.path('/access-result/');
                }
                $anchorScroll();

            });

        }   


	};
    /*
	$scope.modalHowUseInfo = function() {
        var modal = $uibModal.open({
        	size: 'md',
        	controller: 'ModalCtrl',
            //appendTo: $document.find('.frame-content').eq(0),
        	templateUrl: '/ngviews/modals/how-we-use.html'
       	});
        $timeout(function(){
            if(window.parent) {
                window.parent.postMessage(["setLVPlannerScroll"], "*"); 
            }
        });

	};
    */

    $timeout(function(){
        $("[match-height=true]").matchHeight({});
        if(window.parent) {
            window.parent.postMessage(["setLVPlannerScroll"], "*"); 
        }
    });

}]);


