'use strict';

angular.module('myApp.ChatAvailabilityService', [])

.service('ChatAvailabilityService', function () {

    var self = this;

    this.canShowChat = function () {

		var now = moment();
		var day = parseInt(now.format("E"));
		var hour = parseInt(now.format("H"));
		//console.log("day="+day);
		//console.log("hour="+hour);
		//return day > 0 && day <= 5 && hour >= 9 && hour < 18;

		return false;

    };


})

;


