'use strict';

angular.module('myApp', [
  'ngRoute',
  'ngStorage',
  'ui.bootstrap',
  'myApp.UserService',
  'myApp.PersistanceService',
  'myApp.PlannerService',
  'myApp.AccessService',
  'myApp.ResultSelectionService',
  'myApp.ChatAvailabilityService',
  'myApp.ModalCtrl',
  'myApp.NeedHelpModalCtrl',
  'myApp.FinalSalaryWarningModalCtrl',
  'myApp.LandingCtrl',
  'myApp.ClientDetailsCtrl',
  'myApp.ContactDetailsCtrl',
  'myApp.PlannerResultCtrl',
  'myApp.AccessResultCtrl',
  'myApp.GDPRShieldDirective',
  'myApp.DontMissOutDirective',
  'myApp.HelpPanelDirective',
  'myApp.NeedHelpDirective',
  'myApp.PensionDetailsDirective',
  'myApp.InputFormatDirective',
  'myApp.ToolTipDirective',
  'myApp.PlannerResultBoostedContentDirective',
  'myApp.AccessResultBlendedContentDirective',
  'myApp.RequestACallBackFormDirective',
  'myApp.EmailUsAQuestionFormDirective',
  'myApp.CustomCurrencyFilter',
  'myApp.StringToFloatFilter'
]
//,function($interpolateProvider) {
//    $interpolateProvider.startSymbol('<%');
//    $interpolateProvider.endSymbol('%>');
//}
).config(['$locationProvider', '$routeProvider', function($locationProvider, $routeProvider) {

	$locationProvider.hashPrefix('!');
	//$locationProvider.html5Mode(true);

	$routeProvider
	.when('/', {
		templateUrl: '/ngviews/landing/landing.html',
		controller: 'LandingCtrl'
	})
	.when('/client-details', {
		templateUrl: '/ngviews/client-details/client-details.html',
		controller: 'ClientDetailsCtrl'
	})
	.when('/contact-details', {
		templateUrl: '/ngviews/contact-details/contact-details.html',
		controller: 'ContactDetailsCtrl'
	})
  .when('/planner-result', {
    templateUrl: '/ngviews/planner-result/planner-result.html',
    controller: 'PlannerResultCtrl'
  })
  .when('/access-result', {
    templateUrl: '/ngviews/access-result/access-result.html',
    controller: 'AccessResultCtrl'
  })
;




}]);



(function ($) {

	//iframe resizing
    $(function () {
    	$(".frame-content").data("lastHeight",0);
    	window.setInterval(function(){
  			var height = $(".frame-content").height();
  			if(height != $(".frame-content").data("lastHeight")) {
          //$(".frame-content").css('min-height', height + 'px');
          $(".frame-content").data("lastHeight",height);
          if(window.parent) {
            window.parent.postMessage(["setLVPlannerHeight", height], "*"); 
          }
  			}
        if($(".modal-content").length > 0) {
          var modalHeight = $(".modal-content").height() + 140;
          if(modalHeight > $(".frame-content").data("lastHeight")) {
            $(".frame-content").data("lastHeight",modalHeight);
            //$(".frame-content").css('min-height', modalHeight + 'px');
            //console.log("modalheight", modalHeight);
            if(window.parent) {
              window.parent.postMessage(["setLVPlannerHeight", modalHeight], "*"); 
            }
          }

        }

    	},200);
    });




}(jQuery));
