'use strict';

angular.module('myApp.PlannerResultBoostedContentDirective', [])

.directive('plannerresultboostedcontent', function (ChatAvailabilityService,UserService,$timeout) {

    return {
        restrict: 'A',
        replace: true,
        scope: {
        },
        templateUrl: '/ngviews/planner-result/boosted-content-directive.html',
        link: function(scope, element, attr) {

        },
        controller: function ($scope) {


            $timeout(function(){
                $("[match-height-promos=true]").matchHeight({});
            });


        }
    };

})

;