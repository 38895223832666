'use strict';

angular.module('myApp.HelpPanelDirective', [])

.directive('helppanel', function () {

    return {
        restrict: 'A',
        replace: true,
        scope: {
            user: '=user',
            session: '=session',
            telephone: '@telephone'
        },
        templateUrl: '/ngviews/panels/helppanel-directive.html',
        controller: function ($scope, $uibModal, $document, $timeout, ChatAvailabilityService) {


            $scope.modalEmailUsAQuestionForm = function() {
                var modal = $uibModal.open({
                    size: 'md',
                    controller: 'ModalCtrl',
                    appendTo: $document.find('.frame-content').eq(0),
                    scope: $scope,
                    templateUrl: '/ngviews/modals/email-us-a-question-form.html'
                });
                $timeout(function(){
                    if(window.parent) {
                        window.parent.postMessage(["setLVPlannerScroll"], "*"); 
                    }
                });

            };

            $scope.modalRequestACallbackForm = function() {
                var modal = $uibModal.open({
                    size: 'md',
                    controller: 'ModalCtrl',
                    appendTo: $document.find('.frame-content').eq(0),
                    scope: $scope,
                    templateUrl: '/ngviews/modals/request-a-call-back-form.html'
                });
                $timeout(function(){
                    if(window.parent) {
                        window.parent.postMessage(["setLVPlannerScroll"], "*"); 
                    }
                });

            };

            $scope.canShowChat = function() {

                return ChatAvailabilityService.canShowChat();
            }

            $timeout(function(){
                $("[match-height=true]").matchHeight({});
            });



        }
    };

})

;