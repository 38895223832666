'use strict';

angular.module('myApp.CustomCurrencyFilter', [])

	.filter('CustomCurrencyFilter', [ '$filter', function ($filter) {

		return function (amount, currencySymbol) {
            if(Math.floor(amount) == amount) {
                return $filter('currency')(amount, currencySymbol, 0);
            } else {
                return $filter('currency')(amount, currencySymbol, 2);
            }
		};
	}
])

;