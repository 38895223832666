'use strict';

angular.module('myApp.AccessService', [])

.service('AccessService', function (UserService, $filter) {

    var self = this;
    var RATESAMPLEVALUE = 50000.0;
//    var DRAWDOWNRATE = 0.07;
    var DRAWDOWNRATE = 0.06;
    ///JA 03/04/2019
    //var WEEKLYSTATEPENSION = 164.35;
    //var WEEKLYSTATEPENSION = 168.60;
    var WEEKLYSTATEPENSION = 175.20;

	this.totalTaxFreeLumpSum = function(pensionValue) {
		
    	var value = $filter('StringToFloatFilter')(pensionValue);

		return Math.round(value * 0.25);

	};

	this.pensionPotAfterTaxFreeLumpSum = function (pensionValue) {

    	var value = $filter('StringToFloatFilter')(pensionValue);

		return value - this.totalTaxFreeLumpSum(pensionValue);

	};

	this.calcStatePensionIncome = function () {
		return WEEKLYSTATEPENSION;
	};


	this.guaranteedIncome = function (pensionValue, pensionAge) {
		var sampleRate = _.find(this.GIRates(), function(o){
			return parseInt(o.age) === parseInt(pensionAge);
		})
		if(sampleRate) {
			console.log('guaranteedIncome rate = '+sampleRate.rate);
			return (parseFloat(pensionValue) * (parseFloat(sampleRate.rate) / RATESAMPLEVALUE) / 12.0);
		}
		return 0;
	};

	this.drawdownIncome = function (pensionValue) {
		return (parseFloat(pensionValue) * DRAWDOWNRATE) / 12.0;
	};

	this.fixedIncome = function (pensionValue, pensionAge) {
		var sampleRate = _.find(this.FTARates(), function(o){
			return parseInt(o.age) === parseInt(pensionAge);
		})
		if(sampleRate) {
			console.log('fixedIncome rate = '+sampleRate.rate);
			return (parseFloat(pensionValue) * (parseFloat(sampleRate.rate) / RATESAMPLEVALUE) / 12.0);
		}
		return 0;
	};

	this.drawdownIncomeBlended = function (pensionValue) {
		return this.drawdownIncome(pensionValue) * 0.5;
	};

	this.fixedIncomeBlended = function (pensionValue, pensionAge) {
		return this.fixedIncome(pensionValue, pensionAge) * 0.5;
	};

	this.totalIncomeBlended = function(drawdownIncomeBlended, fixedIncomeBlended) {

		return Math.round($filter('StringToFloatFilter')(drawdownIncomeBlended)) + Math.round($filter('StringToFloatFilter')(fixedIncomeBlended));

	};



	this.GIRates = function() {
		return [
		 { "age": 50, "rate": 1658  },
		 { "age": 51, "rate": 1691  },
		 { "age": 52, "rate": 1725  },
		 { "age": 53, "rate": 1761  },
		 { "age": 54, "rate": 1799  },
		 { "age": 55, "rate": 1839  },
		 { "age": 56, "rate": 1881  },
		 { "age": 57, "rate": 1926  },
		 { "age": 58, "rate": 1973  },
		 { "age": 59, "rate": 2023  },
		 { "age": 60, "rate": 2076  },
		 { "age": 61, "rate": 2132  },
		 { "age": 62, "rate": 2184  },
		 { "age": 63, "rate": 2240  },
		 { "age": 64, "rate": 2300  },
		 { "age": 65, "rate": 2363  },
		 { "age": 66, "rate": 2432  },
		 { "age": 67, "rate": 2509  },
		 { "age": 68, "rate": 2592  },
		 { "age": 69, "rate": 2684  },
		 { "age": 70, "rate": 2785  },
		 { "age": 71, "rate": 2896  },
		 { "age": 72, "rate": 3013  },
		 { "age": 73, "rate": 3141  },
		 { "age": 74, "rate": 3279  },
		 { "age": 75, "rate": 3431  },
		 { "age": 76, "rate": 3598  },
		 { "age": 77, "rate": 3789  },
		 { "age": 78, "rate": 3993  },
		 { "age": 79, "rate": 4220  },
		 { "age": 80, "rate": 4471  },
		 { "age": 81, "rate": 4748  },
		 { "age": 82, "rate": 5062  },
		 { "age": 83, "rate": 5398  },
		 { "age": 84, "rate": 5767  },
		 { "age": 85, "rate": 6182  },
		 { "age": 86, "rate": 6632  },
		 { "age": 87, "rate": 7127  },
		 { "age": 88, "rate": 7673  },
		 { "age": 89, "rate": 8245  },
		 { "age": 90, "rate": 8861  }		
		];
	};

	this.FTARates = function () {
		return [
		{ "age": 55, "rate": 5115.83966973511  },
		{ "age": 56, "rate": 5116.0875042144  },
		{ "age": 57, "rate": 5116.36136991831  },
		{ "age": 58, "rate": 5116.64729752759  },
		{ "age": 59, "rate": 5116.94348753686  },
		{ "age": 60, "rate": 5117.24130493956  },
		{ "age": 61, "rate": 5117.55597147246  },
		{ "age": 62, "rate": 5117.90384357713  },
		{ "age": 63, "rate": 5118.28414272324  },
		{ "age": 64, "rate": 5118.71280548036  },
		{ "age": 65, "rate": 5119.22658836657  },
		{ "age": 66, "rate": 5119.83941875464  },
		{ "age": 67, "rate": 5120.61035642243  },
		{ "age": 68, "rate": 5121.55338037652  },
		{ "age": 69, "rate": 5122.68404584593  },
		{ "age": 70, "rate": 5124.04560334492  },
		{ "age": 71, "rate": 5125.71625613952  },
		{ "age": 72, "rate": 5127.72931719259  },
		{ "age": 73, "rate": 5130.11260462567  },
		{ "age": 74, "rate": 5132.89649056434  },
		{ "age": 75, "rate": 5136.1338876896  },
		{ "age": 76, "rate": 5136.1338876896  },
		{ "age": 77, "rate": 5136.1338876896  },
		{ "age": 78, "rate": 5136.1338876896  },
		{ "age": 79, "rate": 5136.1338876896  },
		{ "age": 80, "rate": 5136.1338876896  }
		];
	}

})

;


