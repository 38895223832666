'use strict';

angular.module('myApp.NeedHelpDirective', [])

.directive('needhelp', function ($uibModal,$templateCache,$timeout,$document) {

    return {
        restrict: 'A',
        replace: true,
        scope: {
            user: '=user',
            session: '=session'
        },
        templateUrl: '/ngviews/panels/need-help.html',
        controller: function ($scope,$timeout, $document) {

        	$scope.showNeedHelpModal = function() {

                $('.panel-can-have-help-modal').addClass('panel-with-help-modal');

        		$templateCache.remove('/ngviews/modals/need-help.html');

		        var modal = $uibModal.open({
		        	size: 'lg',
		        	controller: 'NeedHelpModalCtrl',
                    appendTo: $document.find('.frame-content').eq(0),
		            //appendTo: $document.find('.frame-content').eq(0),
		        	//templateUrl: '/ngviews/modals/need-help.html?time=' + (new Date().getTime())
		        	templateUrl: '/ngviews/modals/need-help.html'
		       	});

                $timeout(function(){
                    if(window.parent) {
                        window.parent.postMessage(["setLVPlannerScroll"], "*"); 
                    }
                });

        	}

        }
    };

})

;