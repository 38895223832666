'use strict';

angular.module('myApp.RequestACallBackFormDirective', [])

.directive('requestacallbackform', function (UserService,$timeout) {

    return {
        restrict: 'A',
        replace: true,
        scope: {
            user: '=user',
            session: '=session',
            usage: '@usage'
        },
        templateUrl: '/ngviews/forms/request-a-call-back-form-directive.html',
        link: function(scope, element, attr) {

        },
        controller: function ($scope) {

            $scope.forms = {};

            $scope.callBackDays = [
                "Any ",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
            ];





            $scope.callBackTimes = [
                "8.30am - 10am",
                "10am - 12pm",
                "12pm - 2pm", 
                "2pm - 4pm", 
                "4pm - 6pm"
            ];

            if($scope.details === undefined) {
                $scope.details = {
                    // contactName : $scope.user.firstName,
                    contactName : $scope.user.contactFirstName + ' ' + $scope.user.contactSurname,
                    contactTelephone : $scope.user.contactTelephone,
                    //bestDayToCall : $scope.callBackDays[0],
                    //bestTimeToCall : $scope.callBackTimes[0],
                    bestDayToCall : "",
                    bestTimeToCall : "",
                    message : ""
                };
            }

            $scope.validate = function () {
                if ($scope.forms.form.$valid) {
                    return true;
                }
                //console.log($scope.forms.form);
                angular.forEach($scope.forms.form.$error, function(controls, errorName) {
                    angular.forEach(controls, function(control) {
                        control.$setDirty();
                    });
                });     
                return false;
            }

            $scope.isSubmitted = function() {
                if($scope.usage === "AccessResult" || $scope.usage === "PlannerResult" ) {
                    return $scope.session.ctaRequestACallBackFormSubmitted;
                } else if($scope.usage === "NeedHelp") {
                    return $scope.session.needHelpRequestACallBackFormSubmitted;
                } else if($scope.usage === "FinalSalary") {
                    return $scope.session.finalSalaryRequestACallBackFormSubmitted;
                }
            }

            $scope.doRequestACallBackForm = function(){

                if ($scope.validate()) {

                    if($scope.usage === "AccessResult" || $scope.usage === "PlannerResult" ) {
                        $scope.user.ctaType = "Request Callback";
                        $scope.user.ctaContactName = $scope.details.contactFirstName + ' ' + $scope.details.contactSurname;
                        $scope.user.ctaContactTelephone = $scope.details.contactTelephone;
                        $scope.user.ctaRequestACallBackBestDayToCall = $scope.details.bestDayToCall;
                        $scope.user.ctaRequestACallBackBestTimeToCall = $scope.details.bestTimeToCall;
                        $scope.user.ctaMessage = $scope.details.message;
                    } else if($scope.usage === "NeedHelp") {
                        $scope.user.needHelpType = "Request Callback";
                        $scope.user.needHelpContactName = $scope.details.contactFirstName + ' ' + $scope.details.contactSurname;
                        $scope.user.needHelpContactTelephone = $scope.details.contactTelephone;
                        $scope.user.needHelpRequestACallBackBestDayToCall = $scope.details.bestDayToCall;
                        $scope.user.needHelpRequestACallBackBestTimeToCall = $scope.details.bestTimeToCall;
                        $scope.user.needHelpMessage = $scope.details.message;
                    } else if($scope.usage === "FinalSalary") {
                        $scope.user.finalSalaryType = "Request Callback";
                        $scope.user.finalSalaryContactName = $scope.details.contactFirstName + ' ' + $scope.details.contactSurname;
                        $scope.user.finalSalaryContactTelephone = $scope.details.contactTelephone;
                        $scope.user.finalSalaryRequestACallBackBestDayToCall = $scope.details.bestDayToCall;
                        $scope.user.finalSalaryRequestACallBackBestTimeToCall = $scope.details.bestTimeToCall;
                        $scope.user.finalSalaryMessage = $scope.details.message;
                    }

                    UserService.updateSession(function(){

                        $timeout(function(){
                            UserService.boostBlend(function(){

                                UserService.completeDataCapture($scope.usage, function(){});
                                if($scope.usage === "AccessResult" || $scope.usage === "PlannerResult" ) {
                                    $scope.session.ctaRequestACallBackFormSubmitted = true;
                                } else if($scope.usage === "NeedHelp") {
                                    $scope.session.needHelpRequestACallBackFormSubmitted = true;
                                } else if($scope.usage === "FinalSalary") {
                                    $scope.session.finalSalaryRequestACallBackFormSubmitted = true;
                                }
                                
                            });
                        });



                    });
                }    
            };



        }
    };

})

;