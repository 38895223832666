'use strict';

angular.module('myApp.StringToFloatFilter', [])

	.filter('StringToFloatFilter', [ '$filter', function ($filter) {

		return function (amount) {
	        if (("" + amount).length > 0) {
	            amount = ("" + amount).replace(/[^\d.]/g, '');
	            amount = parseFloat(amount);
	            return amount;
	        } else {
	        	return 0;
	        }

		};
	}
])

;