'use strict';

angular.module('myApp.PlannerResultCtrl', ['ngRoute'])



.controller('PlannerResultCtrl', 
	['$scope','$location','$anchorScroll','UserService', '$filter', '$timeout', 'PlannerService', '$uibModal', '$document',
		function($scope, $location, $anchorScroll,UserService, $filter, $timeout, PlannerService, $uibModal, $document ) {

	$scope.user = UserService.getUser();
	$scope.session = UserService.getSession()

	$scope.boosted = false;
	$scope.user.planner.pensionValueWithGrowth = PlannerService.pensionValueWithGrowth($scope.user);
	$scope.user.planner.pensionValueWithGrowthBoosted = 0;
	$scope.user.planner.taxFreeLumpSum = PlannerService.totalTaxFreeLumpSum($scope.user.planner.pensionValueWithGrowth);
	$scope.user.planner.pensionValueWithGrowthAfterTaxFreeLumpSum = $scope.user.planner.pensionValueWithGrowth - $scope.user.planner.taxFreeLumpSum; 
	$scope.user.planner.taxFreeLumpSumBoosted = 0;
	$scope.user.planner.monthlyIncome = PlannerService.totalMonthlyIncome($scope.user.planner.pensionValueWithGrowthAfterTaxFreeLumpSum);
	$scope.user.planner.monthlyIncomeBoosted = 0;
	console.log($scope.user);

	$scope.setInitialCounter = function() {
		$scope.$broadcast('setInitialCounter', {
			pensionValueWithGrowth : $scope.user.planner.pensionValueWithGrowth, 
		});

	}
	UserService.updateSessionAndPersist(function(){

        //$timeout(function(){
        //    UserService.boostBlend(function(){});
        //});

    });

	$scope.doBoost = function () {

	    $timeout(function(){
	        if(window.parent) {
	            window.parent.postMessage(["setLVPlannerScroll"], "*"); 
	        }
	    });
		$scope.user.planner.pensionValueWithGrowthBoosted = PlannerService.pensionValueWithGrowthBoost($scope.user);
		$scope.user.planner.taxFreeLumpSumBoosted = PlannerService.totalTaxFreeLumpSum($scope.user.planner.pensionValueWithGrowthBoosted);
		$scope.user.planner.pensionValueWithGrowthAfterTaxFreeLumpSumBoosted = $scope.user.planner.pensionValueWithGrowthBoosted - $scope.user.planner.taxFreeLumpSumBoosted; 
		$scope.user.planner.monthlyIncomeBoosted = PlannerService.totalMonthlyIncome($scope.user.planner.pensionValueWithGrowthAfterTaxFreeLumpSumBoosted);

        $scope.boosted = true;
        UserService.boostBlend(function(){});
        $timeout(function(){
            $("[match-height=true]").matchHeight({});
            $("[match-height2=true]").matchHeight({});
            $("[match-height-boosted=true]").matchHeight({});


        });
	};	


    $scope.modalEstimatedPotAssumptions = function(){
		$('.panel-can-have-assumptions-modal').addClass('panel-with-assumptions-modal');
        var modal = $uibModal.open({
        	size: 'md',
        	appendTo: $document.find('.frame-content').eq(0),
        	controller: 'ModalCtrl',
        	templateUrl: '/ngviews/modals/planner-estimated-pot-assumptions.html'
       	});
		$timeout(function(){
			if(window.parent) {
				window.parent.postMessage(["setLVPlannerScroll"], "*"); 
			}
		});
    };
    $scope.modalBoostedAssumptions = function(){
		$('.panel-can-have-assumptions-modal').addClass('panel-with-assumptions-modal');
        var modal = $uibModal.open({
        	size: 'md',
        	appendTo: $document.find('.frame-content').eq(0),
        	controller: 'ModalCtrl',
        	templateUrl: '/ngviews/modals/planner-boosted-assumptions.html'
       	});
		$timeout(function(){
			if(window.parent) {
				window.parent.postMessage(["setLVPlannerScroll"], "*"); 
			}
		});
    };

    $scope.modalAssumptions = function(){
    	if($scope.boosted) {
    		$scope.modalBoostedAssumptions();
    	} else {
    		$scope.modalEstimatedPotAssumptions();
    	}
    	
    };

    $scope.gotoBack = function () {
        $location.path('/client-details/');
        $anchorScroll();
    };

    $timeout(function(){
        $("[match-height=true]").matchHeight({});
        $("[match-height2=true]").matchHeight({});
        $("[match-height-boosted=true]").matchHeight({});

        if(window.parent) {
            window.parent.postMessage(["setLVPlannerScroll"], "*"); 
        }
    });


}]);


